// extracted by mini-css-extract-plugin
export var ArtistDescription = "JordyKerwick-module--ArtistDescription--0ubFs";
export var ArtistInfos = "JordyKerwick-module--ArtistInfos--74qRp";
export var ButtonWrapper = "JordyKerwick-module--ButtonWrapper --QM90h";
export var CardWrapper = "JordyKerwick-module--CardWrapper--gsqUH";
export var CarrouselWrapper2 = "JordyKerwick-module--CarrouselWrapper2--ffe7k";
export var Citations = "JordyKerwick-module--Citations--9gzcy";
export var DescriptionWrapper = "JordyKerwick-module--DescriptionWrapper--cz1mK";
export var ImageWrapper = "JordyKerwick-module--ImageWrapper--q73ve";
export var LinkWrapper = "JordyKerwick-module--LinkWrapper--zp6jt";
export var MobileProtrait = "JordyKerwick-module--MobileProtrait--X2CYl";
export var More = "JordyKerwick-module--More--niaQ8";
export var MoreButton = "JordyKerwick-module--MoreButton--zU8nX";
export var NameWrapper = "JordyKerwick-module--NameWrapper--TDU8j";
export var PdpWrapper = "JordyKerwick-module--PdpWrapper--X5OOD";
export var PhotosWrapper = "JordyKerwick-module--PhotosWrapper--NcpVe";
export var ProfilWrapper = "JordyKerwick-module--ProfilWrapper--9sMns";
export var TitleWrapper = "JordyKerwick-module--TitleWrapper--DTrl4";
export var Wrapper = "JordyKerwick-module--Wrapper--1Vn7e";